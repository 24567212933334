<template>
  <div class="personal-main">
    <div class="personal-info">
      <div class="avatar">
        <img :src="personalInfos.avatar" alt="">
        <!-- <el-upload
          ref="uploadFile"
          class="upload-demo"
          action=""
          multiple
          :file-list="fileList"
          :on-exceed="handleExceed"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :http-request="httpRequest"
          :before-upload="beforeUpload"
        > -->
        <div class="update-avatar-box" @click="toggleShow">
          <img src="@/assets/icon/update-avatar.png" alt="">
        </div>
        <!-- </el-upload> -->
      </div>
      <div class="information">
        <div class="info-top-show">
          <div class="left-show">登录账号：<span class="val">{{ personalInfos.account }}</span></div>
          <div class="right-show">账号创建时间：<span class="val">{{ personalInfos.createTime }}</span></div>
        </div>
        <div class="info-bottom-show">
          <div class="left-show login-nickname">登录昵称：
            <el-input
              v-if="showInput"
              v-model="personalInfos.nickName"
              class="input-show"
              size="mini"
              style="width: 66%;"
              @blur="handleBlurSubmit(personalInfos.nickName)"
              @keyup.enter="handleKeyupSubmit(personalInfos.nickName)"
            />
            <span v-if="!showInput" class="val">{{ personalInfos.nickName }}</span>
            <img v-if="!showInput" class="update-nickname" src="@/assets/icon/update-nickname.png" alt="" @click.stop="updateNicknameClick">
          </div>
          <div class="right-show">上次登录时间：<span class="val">{{ personalInfos.lastLoginTime }}</span></div>
        </div>
      </div>
    </div>
    <!--修改头像-->
    <div class="update-avatar">
      <div class="avatar-style">
        <my-upload v-show="isShow" v-model="show" field="img" :no-circle="true" :width="100" :height="100" img-format="jpg" :size="size" @crop-success="cropSuccess" />
        <!-- <div @click="toggleShow">修改头像</div> -->
      </div>
    </div>
    <!--修改信息-->
    <div class="setting-info">
      <div>
        <div class="set-info-box">
          <div class="flex-style">
            <div class="key-style">
              登录密码
            </div>
            <div class="desc">
              安全性高的密码可以使账号更安全。建议您定期更换密码，设置一个包含字母，符号，或数字中至少两项且长度超过6位的密码。
            </div>
          </div>
          <div>
            <div class="update">
              <el-button type="text" @click="updatePass">修改</el-button>
            </div>
          </div>
        </div>
        <div class="set-info-box">
          <div class="flex-style">
            <div class="key-style">
              手机绑定
            </div>
            <div class="desc">
              您已绑定了手机
              <span style="color: #437AEC;">{{ personalInfos.mobile }}</span>
              [ 您的手机号可以直接用于找回密码等 ]。
            </div>
          </div>
          <div>
            <div class="update">
              <el-button type="text" @click="updatePhone">修改</el-button>
            </div>
          </div>
        </div>
        <div class="set-info-box">
          <div class="flex-style">
            <div class="key-style">
              邮箱绑定
            </div>
            <div class="desc">
              <span v-if="personalInfos.email !== '' && personalInfos.email !== null">您已绑定了邮箱</span>
              <span v-else>您还未绑定邮箱</span>
              <span style="color: #437AEC;">{{ personalInfos.email }}</span>
            </div>
          </div>
          <div>
            <div class="update">
              <el-button v-if="personalInfos.email !== '' && personalInfos.email !== null" type="text" @click="updateEmail">修改</el-button>
              <el-button v-else type="text" @click="updateEmail">绑定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--修改密码-->
    <div class="dialog-container">
      <el-dialog
        v-model="passVisible"
        title="修改密码"
        :close-on-click-modal="false"
        :before-close="handleClosePsd"
        width="35%"
      >
        <div class="dialog-content">
          <!--基本信息-->
          <div class="base-info">
            <div>
              <el-form ref="passInfoForm" :rules="passInfoRules" :model="passInfo" size="small">
                <div class="dialog-form">
                  <!-- <el-form-item prop="currentPassword" label="当前密码" label-width="6em">
                      <el-input v-model="passInfo.currentPassword" show-password placeholder="请输入当前密码" />
                    </el-form-item> -->
                  <el-form-item prop="newPassword" label="新密码" label-width="7em">
                    <el-input v-model="passInfo.newPassword" show-password placeholder="请输入新密码" />
                  </el-form-item>
                  <el-form-item prop="checkPass" label="确认密码" label-width="7em">
                    <el-input v-model="passInfo.checkPass" show-password placeholder="请再次输入新密码" />
                  </el-form-item>
                  <el-form-item prop="msgCode" label="手机验证码" label-width="7em">
                    <el-input v-model="passInfo.msgCode" style="width:50%" placeholder="请输入手机验证码" />
                    <el-button
                      type="primary"
                      :disabled="isDisabledPsd"
                      class="send-msg"
                      @click="sendMsgPsdHandle"
                    >{{ buttonNamePsd }}</el-button>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <el-button size="small" @click="handleCancelPassword">取 消</el-button>
          <el-button :loading="psdConfirm" size="small" type="primary" @click="handlepassInfoCreate">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!--修改邮箱-->
    <div class="dialog-container">
      <el-dialog
        v-model="emailVisible"
        :close-on-click-modal="false"
        :before-close="handleCloseEmail"
        width="35%"
      >
        <!-- <div v-if="personalInfos.email !== '' && personalInfos.email !== null" slot="title">修改邮箱</div>
        <div v-else slot="title">绑定邮箱</div> -->
        <template #title>
          <div v-if="personalInfos.email !== '' && personalInfos.email !== null">修改邮箱</div>
          <div v-else>绑定邮箱</div>
        </template>
        <div class="dialog-content">
          <!--基本信息-->
          <div class="base-info">
            <div>
              <el-form ref="emailInfoForm" :rules="emailInfoRules" :model="emailInfo" size="small">
                <div class="dialog-form">
                  <el-form-item prop="newEmail" label="新邮箱" label-width="7em">
                    <el-input v-model="emailInfo.newEmail" placeholder="请输入新邮箱" />
                  </el-form-item>
                  <el-form-item prop="checkEmail" label="确认邮箱" label-width="7em">
                    <el-input v-model="emailInfo.checkEmail" placeholder="请再次输入新邮箱" />
                  </el-form-item>
                  <el-form-item prop="msgCode" label="手机验证码" label-width="7em">
                    <el-input v-model="emailInfo.msgCode" style="width:50%" placeholder="请输入手机验证码" />
                    <el-button
                      type="primary"
                      :disabled="isDisabledEmail"
                      class="send-msg"
                      @click="sendMsgEmailHandle"
                    >{{ buttonNameEmail }}</el-button>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <el-button size="small" @click="handleCancelEmail">取 消</el-button>
          <el-button :loading="emailConfirm" size="small" type="primary" @click="handleEmailInfoCreate">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!--修改手机号-->
    <div class="dialog-container">
      <el-dialog
        v-model="phoneVisible"
        title="修改手机号"
        :close-on-click-modal="false"
        :before-close="handleClosePhone"
        width="35%"
      >
        <div class="dialog-content">
          <!--基本信息-->
          <div class="base-info">
            <div>
              <el-form ref="phoneInfoForm" :rules="phoneInfoRules" :model="phoneInfo" size="small">
                <div class="dialog-form">
                  <el-form-item prop="newMobile" label="新手机号" label-width="7em">
                    <el-input v-model="phoneInfo.newMobile" placeholder="请输入新手机号" />
                  </el-form-item>
                  <el-form-item prop="checkMobile" label="确认手机号" label-width="7em">
                    <el-input v-model="phoneInfo.checkMobile" placeholder="请再次输入新手机号" />
                  </el-form-item>
                  <el-form-item prop="msgCode" label="手机验证码" label-width="7em">
                    <el-input v-model="phoneInfo.msgCode" style="width:50%" placeholder="请输入手机验证码" />
                    <el-button
                      type="primary"
                      :disabled="isDisabledPhone"
                      class="send-msg"
                      @click="sendMsgPhoneHandle"
                    >{{ buttonNamePhone }}</el-button>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <el-button size="small" @click="handleCancelPhone">取 消</el-button>
          <el-button :loading="phoneConfirm" size="small" type="primary" @click="handlePhoneInfoCreate">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import 'babel-polyfill'
import myUpload from 'vue-image-crop-upload'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { ElMessage } from 'element-plus'
import validate from '@/utils/baseValidate.js'
import { getEmailSms, getPhoneSms, getPasswordSms } from '@/api/sms'
import { getInfo, userUpdatePassword, userUpdatePhone, userUpdateEmail, userUpdateAvatar, mobileCheck, emailCheck, userUpdateNickName } from '@/api/user'
export default {
  components: { 'my-upload': myUpload },
  // beforeRouteLeave为当前路由改变前触发事件，路由跳转时，保存倒计时相关信息
  beforeRouteLeave(to, from, next) {
    /*
      to:router 即将要进入的路由对象
      from:router  当前导航正要离开的路由
      next()进行管道中的下一个钩子
      最后要确保调用next方法，否则钩子不会被resolved
    */
    sessionStorage.setItem(
      'CountDownPsdInfo',
      JSON.stringify({
        CountDownPsd: this.CountDownPsd,
        isDisabledPsd: this.isDisabledPsd
      })
    )
    sessionStorage.setItem(
      'CountDownPhoneInfo',
      JSON.stringify({
        CountDownPhone: this.CountDownPhone,
        isDisabledPhone: this.isDisabledPhone
      })
    )
    sessionStorage.setItem(
      'CountDownEmailInfo',
      JSON.stringify({
        CountDownEmail: this.CountDownEmail,
        isDisabledEmail: this.isDisabledEmail
      })
    )
    next()
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('请输入手机号'))
      } else {
        if (!/^1[3456789]\d{9}$/.test(value.trim())) {
          callback(new Error('请输入正确的手机号'))
        } else {
          mobileCheck(value.trim(), this.personalInfos.uId).then((response) => {
            if (response.meta.status === 456) {
              callback()
            } else {
              callback(new Error(response.meta.msg))
            }
          })
        }
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('请输入邮箱'))
      } else if (value.trim().length < 1 || value.trim().length > 50) {
        callback(new Error('邮箱长度应在1-50个字符之间'))
      } else {
        emailCheck(value.trim(), this.personalInfos.uId).then((response) => {
          if (response.meta.status === 456) {
            callback()
          } else {
            callback(new Error(response.meta.msg))
          }
        })
      }
    }
    const checkPass = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('请再次输入密码'))
      } else if (value.trim() !== this.passInfo.newPassword.trim()) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const checkEmail = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('请再次输入邮箱'))
      } else if (value.trim() !== this.emailInfo.newEmail.trim()) {
        callback(new Error('两次输入邮箱不一致!'))
      } else {
        callback()
      }
    }
    const checkMobile = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('请再次输入手机号'))
      } else if (value.trim() !== this.phoneInfo.newMobile.trim()) {
        callback(new Error('两次输入手机号不一致!'))
      } else {
        callback()
      }
    }
    return {
      showInput: false,
      showMsg: true,
      count: '',
      timer: null,
      CountDownPsd: 60, // 倒计时
      buttonNamePsd: '获取验证码', // 按钮初始化文字
      isDisabledPsd: false, // 是否禁用按钮
      CountDownPhone: 60,
      buttonNamePhone: '获取验证码',
      isDisabledPhone: false,
      CountDownEmail: 60,
      buttonNameEmail: '获取验证码',
      isDisabledEmail: false,
      psdConfirm: false,
      emailConfirm: false,
      phoneConfirm: false,
      nickNameConfirm: false,
      isShow: false,
      show: false,
      size: 2.1,
      loading: false,
      personalInfos: {},
      initNickName: '',
      passVisible: false,
      emailVisible: false,
      phoneVisible: false,
      nickNameVisible: false,
      passInfo: {
        msgCode: '',
        newPassword: '',
        checkPass: ''
      },
      emailInfo: {
        msgCode: '',
        newEmail: '',
        checkEmail: ''
      },
      phoneInfo: {
        msgCode: '',
        newMobile: '',
        checkMobile: ''
      },
      nickNameInfo: {
        nickName: ''
      },
      passInfoRules: {
        msgCode: [
          { required: true, trigger: 'blur', message: '请输入手机收到的验证码' }
        ],
        newPassword: [
          { required: true, trigger: 'blur', validator: validate.password }
        ],
        checkPass: [
          { required: true, validator: checkPass, trigger: 'blur' }
        ]
      },
      emailInfoRules: {
        msgCode: [
          { required: true, trigger: 'blur', message: '请输入手机收到的验证码' }
        ],
        newEmail: [{ required: true, trigger: 'blur', validator: validateEmail }, { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        checkEmail: [
          { required: true, validator: checkEmail, trigger: 'blur' }
        ]
      },
      phoneInfoRules: {
        msgCode: [
          { required: true, trigger: 'blur', message: '请输入手机收到的验证码' }
        ],
        newMobile: [
          { required: true, trigger: 'blur', validator: validatePhone }
        ],
        checkMobile: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ]
      },
      nickNameInfoRules: {
        nickName: [{ required: true, trigger: 'blur', validator: validate.nickname }]
      },
      timerPsd: null,
      timerPhone: null,
      timerEmail: null
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  mounted() {
    this.getInfo()
    document.getElementsByClassName('personal-main')[0].addEventListener('click', this.closeNickNameInput)
    // 密码
    // 从sessionStorage中读取倒计时相关信息
    if (sessionStorage.getItem('CountDownPsdInfo')) {
      this.CountDownPsd = JSON.parse(
        sessionStorage.getItem('CountDownPsdInfo')
      ).CountDownPsd
      this.isDisabledPsd = JSON.parse(
        sessionStorage.getItem('CountDownPsdInfo')
      ).isDisabledPsd
    }
    // 进入该页面时，若倒计时还在进行，则继续跑倒计时
    if (this.isDisabledPsd) {
      this.timerPsd = setInterval(() => {
        this.CountDownPsd--
        this.buttonNamePsd = this.CountDownPsd + '秒后重试'
        if (this.CountDownPsd <= 0) {
          clearInterval(this.timerPsd)
          this.buttonNamePsd = '获取验证码'
          this.CountDownPsd = 60
          this.isDisabledPsd = false
        }
      }, 1000)
    }
    // 在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem(
        'CountDownPsdInfo',
        JSON.stringify({
          CountDownPsd: this.CountDownPsd,
          isDisabledPsd: this.isDisabledPsd
        })
      )
    })
    // 手机
    // 从sessionStorage中读取倒计时相关信息
    if (sessionStorage.getItem('CountDownPhoneInfo')) {
      this.CountDownPhone = JSON.parse(
        sessionStorage.getItem('CountDownPhoneInfo')
      ).CountDownPhone
      this.isDisabledPhone = JSON.parse(
        sessionStorage.getItem('CountDownPhoneInfo')
      ).isDisabledPhone
    }
    // 进入该页面时，若倒计时还在进行，则继续跑倒计时
    if (this.isDisabledPhone) {
      this.timerPhone = setInterval(() => {
        this.CountDownPhone--
        this.buttonNamePhone = this.CountDownPhone + '秒后重试'
        if (this.CountDownPhone <= 0) {
          clearInterval(this.timerPhone)
          this.buttonNamePhone = '获取验证码'
          this.CountDownPhone = 60
          this.isDisabledPhone = false
        }
      }, 1000)
    }
    // 在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem(
        'CountDownPhoneInfo',
        JSON.stringify({
          CountDownPhone: this.CountDownPhone,
          isDisabledPhone: this.isDisabledPhone
        })
      )
    })
    // 邮箱
    // 从sessionStorage中读取倒计时相关信息
    if (sessionStorage.getItem('CountDownEmailInfo')) {
      this.CountDownEmail = JSON.parse(
        sessionStorage.getItem('CountDownEmailInfo')
      ).CountDownEmail
      this.isDisabledEmail = JSON.parse(
        sessionStorage.getItem('CountDownEmailInfo')
      ).isDisabledEmail
    }
    // 进入该页面时，若倒计时还在进行，则继续跑倒计时
    if (this.isDisabledEmail) {
      this.timerEmail = setInterval(() => {
        this.CountDownEmail--
        this.buttonNameEmail = this.CountDownEmail + '秒后重试'
        if (this.CountDownEmail <= 0) {
          clearInterval(this.timerEmail)
          this.buttonNameEmail = '获取验证码'
          this.CountDownEmail = 60
          this.isDisabledEmail = false
        }
      }, 1000)
    }
    // 在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem(
        'CountDownEmailInfo',
        JSON.stringify({
          CountDownEmail: this.CountDownEmail,
          isDisabledEmail: this.isDisabledEmail
        })
      )
    })
  },
  beforeUnmount() {
    document.getElementsByClassName('personal-main')[0].removeEventListener('click', this.closeNickNameInput)
  },
  methods: {
    formatMobile(phoneNumber) {
      // 将手机号分为前两位、中间部分、后三位
      const prefix = phoneNumber.slice(0, 3)
      const middle = '*'.repeat(phoneNumber.length - 7) // 中间部分用*代替
      const suffix = phoneNumber.slice(-4)
      return `${prefix}${middle}${suffix}`
    },
    formatEmail(email) {
      let new_email = email
      if (String(email).indexOf('@') > 0) {
        const str = email.split('@')
        let _s = ''
        if (str[0].length > 3) {
          for (let i = 0; i < str[0].length - 3; i++) {
            _s += '*'
          }
        }
        new_email = str[0].substr(0, 3) + _s + '@' + str[1]
      }
      return new_email
    },
    closeNickNameInput(e) {
      console.log(this.initNickName)
      const self = this
      if (!document.getElementsByClassName('login-nickname')[0].contains(e.target)) {
        if (this.showInput) {
          this.showInput = false
          this.getInfo()
        }
      }
    },
    // 修改昵称update-nickname
    handleBlurSubmit() {
      this.showInput = false
      this.getInfo()
    },
    handleKeyupSubmit(val) {
      if (val === this.initNickName) {
        this.showInput = false
        return
      }
      userUpdateNickName(val).then(res => {
        if (res.meta.status === 201) {
          this.showInput = false
        } else {
          this.getInfo()
          this.showInput = false
          ElMessage.error(res.meta.msg)
        }
      }).catch(() => {
        this.showInput = false
      })
    },
    updateNicknameClick() {
      this.showInput = true
    },
    // 修改头像
    toggleShow() {
      this.show = !this.show
      this.isShow = true
      this.$nextTick(() => {
        document.querySelector('.vicp-close').addEventListener('click', () => {
          this.isShow = false
        })
        document.querySelector('.vicp-operate').addEventListener('click', () => {
          this.isShow = false
        })
      })
    },
    cropSuccess(imgDataUrl) {
      //  imgDataUrl其实就是图片的base64data码
      this.personalInfos.avatar = imgDataUrl
      console.log(this.personalInfos.avatar)
      userUpdateAvatar({ avatar: imgDataUrl }).then(res => {
        if (res.meta.status === 201) {
          this.getInfo()
          ElMessage.success('修改成功')
        }
      })
      this.isShow = false
    },
    // 获取验证码密码
    sendMsgPsdHandle() {
      // 先把按钮禁止，并进行倒计时，防止网速慢的用户重复点击
      this.isDisabledPsd = true
      this.timerPsd = setInterval(() => {
        this.CountDownPsd--
        this.buttonNamePsd = this.CountDownPsd + '秒后重试'
        if (this.CountDownPsd <= 0) {
          clearInterval(this.timerPsd)
          this.buttonNamePsd = '获取验证码'
          this.CountDownPsd = 60
          this.isDisabledPsd = false
        }
      }, 1000)
      // 操作验证发送API BLOCK
      getPasswordSms().then((res) => {
        if (res.meta.status === 200) {
          ElMessage.success('发送成功')
        } else {
          ElMessage.error(res.meta.msg)
        }
      }).catch(() => {
        // 若接口请求错误，把倒计时关了，按钮恢复可点击状态
        clearInterval(this.timerPsd)
        this.buttonNamePsd = '获取验证码'
        this.CountDownPsd = 60
        this.isDisabledPsd = false
      })
    },
    // 手机验证码
    sendMsgPhoneHandle() {
      // 先把按钮禁止，并进行倒计时，防止网速慢的用户重复点击
      this.isDisabledPhone = true
      this.timerPhone = setInterval(() => {
        this.CountDownPhone--
        this.buttonNamePhone = this.CountDownPhone + '秒后重试'
        if (this.CountDownPhone <= 0) {
          clearInterval(this.timerPhone)
          this.buttonNamePhone = '获取验证码'
          this.CountDownPhone = 60
          this.isDisabledPhone = false
        }
      }, 1000)
      // 操作验证发送API BLOCK
      getPhoneSms().then((res) => {
        if (res.meta.status === 200) {
          ElMessage.success('发送成功')
        } else {
          ElMessage.error(res.meta.msg)
        }
      }).catch(() => {
        // 若接口请求错误，把倒计时关了，按钮恢复可点击状态
        clearInterval(this.timerPhone)
        this.buttonNamePhone = '获取验证码'
        this.CountDownPhone = 60
        this.isDisabledPhone = false
      })
    },
    // 邮箱验证码
    sendMsgEmailHandle() {
      // 先把按钮禁止，并进行倒计时，防止网速慢的用户重复点击
      this.isDisabledEmail = true
      this.timerEmail = setInterval(() => {
        this.CountDownEmail--
        this.buttonNameEmail = this.CountDownEmail + '秒后重试'
        if (this.CountDownEmail <= 0) {
          clearInterval(this.timerEmail)
          this.buttonNameEmail = '获取验证码'
          this.CountDownEmail = 60
          this.isDisabledEmail = false
        }
      }, 1000)
      // 操作验证发送API BLOCK
      getEmailSms().then((res) => {
        if (res.meta.status === 200) {
          ElMessage.success('发送成功')
        } else {
          ElMessage.error(res.meta.msg)
        }
      }).catch(() => {
        // 若接口请求错误，把倒计时关了，按钮恢复可点击状态
        clearInterval(this.timerEmail)
        this.buttonNameEmail = '获取验证码'
        this.CountDownEmail = 60
        this.isDisabledEmail = false
      })
    },
    // 修改密码弹窗
    updatePass(row) {
      // this.showMsg = true
      // clearInterval(this.timer)
      this.timer = null
      this.passInfo.msgCode = ''
      this.passInfo.newPassword = ''
      this.passInfo.checkPass = ''
      this.passVisible = true
    },
    // 取消提交密码信息
    handleCancelPassword() {
      this.passVisible = false
      this.$refs.passInfoForm.resetFields()
      // this.passInfo = {}
    },
    handleClosePsd() {
      this.passVisible = false
      this.$refs.passInfoForm.resetFields()
    },
    // 提交密码信息
    handlepassInfoCreate() {
      this.psdConfirm = true
      this.$refs.passInfoForm.validate((valid) => {
        if (valid) {
          const data = {
            smsCode: this.passInfo.msgCode.trim(),
            newPassword: this.passInfo.newPassword.trim(),
            captchaKey: '',
            code: ''
          }
          userUpdatePassword(data).then(res => {
            if (res.meta.status === 201) {
              this.passVisible = false
              this.psdConfirm = false
              this.$refs.passInfoForm.resetFields()
              ElMessage.success('修改成功')
              clearInterval(this.timerPsd)
              this.buttonNamePsd = '获取验证码'
              this.CountDownPsd = 60
              this.isDisabledPsd = false
            } else {
              ElMessage.error(res.meta.msg)
            }
          })
          this.psdConfirm = false
          // this.$refs.passInfoForm.resetFields()
        } else {
          this.psdConfirm = false
        }
      })
    },
    // 修改手机号弹窗
    updatePhone(row) {
      this.timer = null
      this.phoneInfo.msgCode = ''
      this.phoneInfo.newMobile = ''
      this.phoneInfo.checkMobile = ''
      this.phoneVisible = true
    },
    handleCancelPhone() {
      this.phoneVisible = false
      this.$refs.phoneInfoForm.resetFields()
    },
    handleClosePhone() {
      this.phoneVisible = false
      this.$refs.phoneInfoForm.resetFields()
    },
    // 提交手机号信息
    handlePhoneInfoCreate() {
      this.phoneConfirm = true
      this.$refs.phoneInfoForm.validate((valid) => {
        if (valid) {
          const data = {
            smsCode: this.phoneInfo.msgCode.trim(),
            newPhone: this.phoneInfo.newMobile.trim()
          }
          userUpdatePhone(data).then(res => {
            if (res.meta.status === 201) {
              this.phoneVisible = false
              this.getInfo()
              ElMessage.success('修改成功')
              clearInterval(this.timerPhone)
              this.buttonNamePhone = '获取验证码'
              this.CountDownPhone = 60
              this.isDisabledPhone = false
            } else {
              ElMessage.error(res.meta.msg)
            }
          })
          // }
          this.phoneConfirm = false
          // this.$refs.phoneInfoForm.resetFields()
        } else {
          this.phoneConfirm = false
        }
      })
    },
    // 修改邮箱弹窗
    updateEmail(row) {
      this.timer = null
      this.emailInfo.msgCode = ''
      this.emailInfo.newEmail = ''
      this.emailInfo.checkEmail = ''
      this.emailVisible = true
    },
    handleCancelEmail() {
      this.emailVisible = false
      this.$refs.emailInfoForm.resetFields()
      this.timer = null
    },
    handleCloseEmail() {
      this.emailVisible = false
      this.$refs.emailInfoForm.resetFields()
    },
    // 提交邮箱信息
    handleEmailInfoCreate() {
      this.emailConfirm = true
      this.$refs.emailInfoForm.validate((valid) => {
        if (valid) {
          const data = {
            smsCode: this.emailInfo.msgCode.trim(),
            newEmail: this.emailInfo.newEmail.trim()
          }
          userUpdateEmail(data).then(res => {
            if (res.meta.status === 201) {
              this.emailVisible = false
              this.getInfo()
              ElMessage.success('修改成功')
              clearInterval(this.timerEmail)
              this.buttonNameEmail = '获取验证码'
              this.CountDownEmail = 60
              this.isDisabledEmail = false
            } else {
              ElMessage.error(res.meta.msg)
            }
          })
          // }
          this.emailConfirm = false
          // this.$refs.emailInfoForm.resetFields()
        } else {
          this.emailConfirm = false
        }
      })
    },
    updateNickName() {
      this.nickNameInfo.nickName = this.personalInfos.nickName
      this.nickNameVisible = true
    },
    // 昵称取消修改
    handleCancelnickName() {
      this.nickNameVisible = false
      this.$refs.nickNameInfoForm.resetFields()
    },
    handleCloseNickName() {
      this.nickNameVisible = false
      this.$refs.nickNameInfoForm.resetFields()
    },
    // 提交
    handleNickNameInfoCreate() {
      this.nickNameConfirm = true
      this.$refs.nickNameInfoForm.validate((valid) => {
        if (valid) {
          userUpdateNickName(this.nickNameInfo.nickName.trim()).then(res => {
            if (res.meta.status === 201) {
              this.nickNameVisible = false
              this.nickNameConfirm = false
              this.$refs.nickNameInfoForm.resetFields()
              this.getInfo()
              ElMessage.success('修改成功')
            }
          }).catch(() => {
            this.nickNameVisible = false
            this.nickNameConfirm = false
            this.$refs.nickNameInfoForm.resetFields()
          })
        } else {
          this.nickNameConfirm = false
        }
      })
    },
    getInfo() {
      getInfo(this.token).then(res => {
        if (res.meta.status === 200) {
          this.initNickName = res.data.nickName
          this.personalInfos = res.data
          this.personalInfos.mobile = this.formatMobile(res.data.mobile)
          this.personalInfos.email = this.formatEmail(res.data.email)
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
.personal-main{
  padding: 41px 32px 41px 72px;
  ::v-deep .el-dialog{
  border-radius: 20px;
}
.dialog-footer{
      text-align: right;
      padding-top: 30px;
    }
::v-deep .el-button--default{
    background-color: #fff;
    color: #437AEC;
   }
.send-msg{
      margin-left: 20px;
    }
::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-dialog__body{
      padding: 30px;
    }
    ::v-deep .el-icon{
      color: #333;
    }
    ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
  ::v-deep .el-dialog{
    max-height: 600px;
    .el-dialog__body{
      max-height: 600px;
    }
  }
  .set-info-box{
      height: 96px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      align-items: center;
      border-bottom: 1px solid #E5E6EB;
      .flex-style {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .key-style{
        width: 76px;
        color: #222;
        font-size: 14px;
        font-weight: bold;
      }
      .desc{
        color: #343a40;
        width: 100%;
      }
    }
  .personal-info{
    display: flex;
    align-items: center;
    margin-bottom: 44px;
    .avatar{
      padding-right: 100px;
      position: relative;
      // .upload-demo{
      //   position: absolute;
      //   bottom: 0;
      //   left: 70px;
      //   width: 30px;
      //   height: 30px;
      // }
      .update-avatar {
        padding-bottom: 15px;
        .avatar-style {
          width: 150px;
          cursor: pointer;
          text-align: center;
          color: #437AEC;
          font-size: 14px;
        }
      }
      .update-avatar-box{
        position: absolute;
        bottom: 0;
        left: 70px;
        width: 30px;
        height: 30px;
        background: #F2F3F5;
        border-radius: 50%;
        border: 2px solid #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 12px;
          height: 12px;
        }
      }
      img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    .information{
      width: calc(100% - 200px);
      .info-top-show{
        margin-bottom: 16px;
      }
      .info-top-show,
      .info-bottom-show{
        display: flex;
        color: #303133;
        font-size: 14px;
        .left-show{
          width: 40%;
          display: flex;
          img{
            width: 14px;
            height: 14px;
            margin-top: 3px;
          }
          .update-nickname{
            margin-left: 8px;
          }
        }
        .right-show{
          width: 60%;
        }
      }
      .val{
        color: #606266;
      }
    }
  }
}
</style>
